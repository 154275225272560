import React from 'react';
import Layout from 'src/components/Layout';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import CompanyButton from 'src/components/CompanyButton';
import { Link } from '@reach/router'

export default () => {
  return (
    <Layout>
      <Box component={Container} pt={6} pb={10}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h2" component="h1" data-sal="fade" data-sal-delay="200" data-sal-duration="1000" >
              Confirmation
        </Typography>

            <Box my={3} data-sal="fade" data-sal-delay="400" data-sal-duration="1000" >
              <Typography color="textSecondary" variant="body1">
                Are you looking for a professional team to build your new app or website? <br />
            Want our developers to be a part of your team? <br />
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container alignItems="center">
            <Box component={Typography} variant="body1" data-sal="fade" data-sal-delay="400" data-sal-duration="1000" >
              Send us your inquiry so we can provide you with <Box display="inline" component="strong" fontWeight="fontWeightBold">the best solution</Box>.
        </Box>
          </Grid>

          <Grid item xs={12} md={6} container alignItems="center" data-sal="fade" data-sal-delay="400" data-sal-duration="1000">
            <CompanyButton component={Link} to="/">>Return to homepage</CompanyButton>
          </Grid>
        </Grid>
      </Box>

    </Layout>
  );
}
